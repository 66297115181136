@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

ul li {
  list-style: none;
}

input[type='checkbox'],
input[type='radio'],
input[type='file'] {
  display: none;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="password"],
input[type="email"],
input[type="search"] {
  -webkit-appearance: none;
}

textarea:focus,
input[type="text"]:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="search"]:focus {
  outline: none;
}

label {
  cursor: pointer;
}

margin-64 {
  display: block;
  width: 100%;
  height: 64px;
}

margin-48 {
  display: block;
  width: 100%;
  height: 48px;
}

margin-32 {
  display: block;
  width: 100%;
  height: 32px;
}

margin-24 {
  display: block;
  width: 100%;
  height: 24px;
}

margin-16 {
  display: block;
  width: 100%;
  height: 16px;
}

margin-8 {
  display: block;
  width: 100%;
  height: 8px;
}

margin-4 {
  display: block;
  width: 100%;
  height: 4px;
}

.body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: url("./img/bg-large-min.jpg") no-repeat center;
  background-size: cover;
}

/* десктоп ретина */

@media (min-width: 1024px) and (min-resolution: 144dpi) {
  .body {
    background: url("./img/bg-large@2x-min.jpg") no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
  }
}

/* планшет */

@media (min-width: 640px) and (max-width: 1023px) {
  .body {
    background: url("./img/bg-medium-min.jpg") no-repeat center;
    background-size: cover;
  }
}

/* планшет ретина */

@media (min-width: 640px) and (max-width: 1023px) and (min-resolution: 144dpi) {
  .body {
    background: url("./img/bg-medium@2x-min.jpg") no-repeat center;
    background-size: cover;
  }
}

/* телефон */

@media (max-width: 639px) {
  .body {
    background: url("./img/bg-small-min.jpg") no-repeat center;
    background-size: cover;
  }
}

/* телефон ретина */

@media (max-width: 639px) and (min-resolution: 144dpi) {
  .body {
    background: url("./img/bg-small@2x-min.jpg") no-repeat center;
    background-size: cover;
  }
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 320px;
  -webkit-box-shadow: 0px 32px 48px rgba(0, 68, 117, 0.2), 0px 8px 16px rgba(0, 68, 117, 0.16);
  box-shadow: 0px 32px 48px rgba(0, 68, 117, 0.2), 0px 8px 16px rgba(0, 68, 117, 0.16);
}

@media (max-width: 639px) {
  .form {
    width: 320px;
    margin: auto 0 0 0;
  }
}

.form_356 {
  width: 356px;
}

@media (max-width: 639px) {
  .form_356 {
    width: 320px;
  }
}

.form__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
}

.form__item_top {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

@media (max-width: 639px) {
  .form__item_top {
    z-index: 210;
    width: 320px;
    padding: 16px;
    border-radius: 4px;
  }
}

.form__item_bottom {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  border-top: 1px solid #dbeaf0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media (max-width: 639px) {
  .form__item_bottom {
    position: fixed;
    z-index: 220;
    bottom: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 320px;
    padding: 12px;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.05);
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.05);
  }
}

.form__error {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0px;
  overflow: hidden;
  margin-bottom: -1px;
  padding: 0;
  background: #ffffff;
  -webkit-transition: padding .15s ease-out;
  -o-transition: padding .15s ease-out;
  transition: padding .15s ease-out;
}

.form__error.active {
  height: auto;
  padding: 16px 24px;
  border-top: 1px solid #ffe6d9;
  border-bottom: 1px solid #ffe6d9;
}

@media (max-width: 639px) {
  .form__error.active {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.form__error.active>.form__error-text {
  -webkit-transition: opacity .15s ease-out .2s;
  -o-transition: opacity .15s ease-out .2s;
  transition: opacity .15s ease-out .2s;
  opacity: 1;
}

.form__error.active~.form__item_top {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.form__error-text {
  opacity: 0;
  font-size: 12px;
  line-height: normal;
  color: #ff8242;
}

.popup {
  opacity: 0;
}

.popup.active {
  -webkit-transition: opacity .3s ease-out .5s;
  -o-transition: opacity .3s ease-out .5s;
  transition: opacity .3s ease-out .5s;
  opacity: 1;
}

.popup__bg {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
}

.popup__bg.active {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow-y: scroll;
}

.popup__bg.active>.popup__wrapper>.popup {
  -webkit-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;
  opacity: 1;
}

.popup__wrapper {
  position: relative;
  z-index: 200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow-y: auto;
}

@media (max-width: 639px) {
  .popup__wrapper {
    padding: 16px 0 64px 0;
  }
}

@media (min-width: 1023px) {
  .popup__bg_mob {
    display: none !important;
  }
}

.popup__close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: url("./img/cancel-icon.svg") no-repeat center;
  cursor: pointer;
}

@media (max-width: 639px) {
  .popup__close-icon {
    display: none;
  }
}

.popup__checklist {
  height: 0;
  overflow: hidden;
}

.popup__checklist.active {
  height: auto;
  -webkit-transition: height .2s ease-out;
  -o-transition: height .2s ease-out;
  transition: height .2s ease-out;
}

.popup__checklist-wrapper {
  opacity: 0;
}

.popup__checklist.active>.popup__checklist-wrapper {
  -webkit-transition: opacity .15s ease-out .3s;
  -o-transition: opacity .15s ease-out .3s;
  transition: opacity .15s ease-out .3s;
  opacity: 1;
}

#extraChecklistTrigger.active {
  display: none;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
}

.header__logo {
  width: 125px;
  height: 24px;
  margin-right: 8px;
}

.image-ru {
  background: url("./img/logo.svg") no-repeat center;
}

.image-en {
  background: url("./img/logo-en.svg") no-repeat center;
}

.header__redirect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}

.header__redirect .text {
  /* content: 'Личный кабинет'; */
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
}

@media (max-width: 639px) {
  .header__redirect .text {
    content: 'ЛК';
  }
}

@media (min-width: 1024px) {
  .header__redirect:hover>.header__redirect-icon {
    -webkit-transition: background .15s ease-out;
    -o-transition: background .15s ease-out;
    transition: background .15s ease-out;
    background: url("./img/new-page-icon.hover.svg") no-repeat center;
  }

  .header__redirect:hover::after {
    -webkit-transition: color .15s ease-out;
    -o-transition: color .15s ease-out;
    transition: color .15s ease-out;
    color: #ff8242;
  }
}

.header__redirect-icon {
  width: 32px;
  height: 32px;
  background: url("./img/new-page-icon.svg") no-repeat center;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 48px) */
}

@media (max-width: 639px) {
  .container {
    padding: 0 24px;
  }
}

.container__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 640px;
  margin-right: 24px;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@media (min-width: 1024px) and (max-width: 1619px) {
  .container__item {
    width: 480px;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .container__item {
    width: 480px;
    margin-right: 0;
  }
}

@media (max-width: 639px) {
  .container__item {
    width: 272px;
    margin-right: 0;
  }
}

.container__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container__item-wrapper a:last-child {
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
}

@media (min-width: 1620px) {
  .container__item-wrapper a:last-child {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1619px) {
  .container__item-wrapper a:last-child {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .container__form {
    display: none;
  }
}

@media (max-width: 639px) {
  .container__form {
    display: none;
  }
}

.container__title {
  /* content: 'Телфин.Офис'; */
  font-weight: 700;
  font-size: 40px;
  line-height: normal;
  color: #ffffff;
}

@media (max-width: 639px) {
  .container__title {
    content: 'Телфин.Офис';
    font-size: 30px;
  }
}

.container__text {
  font-weight: 300;
  font-size: 28px;
  line-height: normal;
  color: #FFFFFF;
}

@media (max-width: 639px) {
  .container__text {
    font-size: 20px;
  }
}

.notice {
  background: #fff;
  max-width: 984px;
  margin: 0 auto;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 32px 48px rgb(0 68 117 / 20%), 0px 8px 16px rgb(0 68 117 / 16%);
}

@media (min-width: 1024px) and (max-width: 1619px) {
  .notice {
    max-width: 824px;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .notice {
    max-width: 480px;
  }
}

@media (max-width: 639px) {
  .notice {
    max-width: 320px;
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 48px;
}

@media (max-width: 639px) {
  .footer {
    flex-wrap: wrap;
    height: 72px;
  }
}

.footer__text,
.footer__link {
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
}

.footer__text {
  margin-right: 16px;
}

.footer__link {
  display: inline-flex;
  align-items: center;
}

.footer__link.link {
  color: #ffffff;
}

.footer__link .footer__icon {
  margin-right: 4px;
}

.footer__icon {
  width: 12px;
  height: 12px;
  background: url("./img/new-page-icon.svg") no-repeat center;
}

@media (min-width: 1024px) {
  .footer__link:hover>.footer__icon {
    -webkit-transition: background .15s ease-out;
    -o-transition: background .15s ease-out;
    transition: background .15s ease-out;
    background: url("./img/new-page-icon.hover.svg") no-repeat center;
  }
}

.text {
  font-size: 14px;
  color: #4d4d4d;
  line-height: normal;
}

.text_extra {
  font-size: 12px;
  color: #7e898c;
}

.text_bold {
  font-weight: bold;
}

.title {
  line-height: normal;
  font-weight: 700;
  color: #4d4d4d;
}

.title_h1 {
  font-size: 24px;
}

.title_h2 {
  font-weight: 500;
  font-size: 18px;
}

.title_h3 {
  font-weight: 500;
  font-size: 14px;
}

.input {
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  background: #eaf6fb;
  border: 1px solid #dbeaf0;
  border-radius: 2px;
  outline: none;
  font-size: 14px;
  line-height: normal;
  color: #4d4d4d;
}

.input:focus {
  border: 1px solid #69c1de;
}

.input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: normal;
  text-overflow: ellipsis;
  -webkit-transition: opacity .15s ease;
  -o-transition: opacity .15s ease;
  transition: opacity .15s ease;
  color: #7e898c;
}

.input:-ms-input-placeholder {
  font-size: 14px;
  line-height: normal;
  text-overflow: ellipsis;
  -webkit-transition: opacity .15s ease;
  -o-transition: opacity .15s ease;
  transition: opacity .15s ease;
  color: #7e898c;
}

.input::-ms-input-placeholder {
  font-size: 14px;
  line-height: normal;
  text-overflow: ellipsis;
  -webkit-transition: opacity .15s ease;
  -o-transition: opacity .15s ease;
  transition: opacity .15s ease;
  color: #7e898c;
}

.input::placeholder {
  font-size: 14px;
  line-height: normal;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-transition: opacity .15s ease;
  -o-transition: opacity .15s ease;
  transition: opacity .15s ease;
  color: #7e898c;
}

.input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.input:focus:-ms-input-placeholder {
  opacity: 0;
}

.input:focus::-ms-input-placeholder {
  opacity: 0;
}

.input:focus::placeholder {
  opacity: 0;
}

.input.invalid {
  -webkit-transition: border .15s ease;
  -o-transition: border .15s ease;
  transition: border .15s ease;
  border: 1px solid #ff8242;
}

.input_area {
  width: 308px;
  resize: none;
  height: 64px;
  padding: 8px;
}

@media (max-width: 639px) {
  .input_area {
    width: 100%;
  }
}

.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  padding: 0 16px 2px 16px;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  background: #69c1de;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .btn:hover {
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    background: #ff8242;
  }
}

.btn.disabled {
  opacity: .5;
  cursor: default;
}

@media (min-width: 1024px) {
  .btn.disabled:hover {
    background: #69c1de;
  }
}

.btn_text {
  height: 24px;
  padding: 0;
  border-radius: 0;
  background: none;
  color: #69c1de;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .btn_text:hover {
    background: none;
    color: #ff8242;
  }
}

.btn_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
  padding: 0;
  background: none;
  border: none;
}

@media (min-width: 1024px) {
  .btn_wrapper:hover {
    border: none;
    background: none;
  }
}

@media (min-width: 1024px) {
  .btn_wrapper:hover>.btn__text {
    color: #ff8242;
  }
}

.btn_wrapper-white>.btn__text {
  color: white;
}

.btn__text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #69c1de;
}

.btn__icon {
  width: 32px;
  height: 32px;
}

.btn__icon_cancel {
  background: url("./img/cancel-icon.blue.svg") no-repeat center;
}

.link {
  color: #69c1de;
}

@media (min-width: 1024px) {
  .link:hover {
    -webkit-transition: all .15s ease-out;
    -o-transition: all .15s ease-out;
    transition: all .15s ease-out;
    color: #ff8242;
  }
}

.checkbox {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin: 0 8px 0 0;
  border: 1px solid #dbeaf0;
  border-radius: 2px;
  background: #eaf6fb;
}

.checkbox__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox:checked+.checkbox {
  border: none;
  background: url("./img/check-icon.svg") no-repeat center;
  background-size: contain;
}

.checkbox__label {
  font-size: 14px;
  line-height: normal;
  color: #4d4d4d;
}

@media (max-width: 639px) {
  .mob-none {
    display: none;
  }
}

.mob-block {
  display: none;
}

@media (max-width: 639px) {
  .mob-block {
    display: block;
  }
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  -webkit-box-shadow: 0px 32px 48px rgba(0, 68, 117, 0.2), 0px 8px 16px rgba(0, 68, 117, 0.16);
  box-shadow: 0px 32px 48px rgba(0, 68, 117, 0.2), 0px 8px 16px rgba(0, 68, 117, 0.16);
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.notification.active {
  -webkit-transition: margin-right .3s ease-out, opacity .3s ease-out;
  -o-transition: margin-right .3s ease-out, opacity .3s ease-out;
  transition: margin-right .3s ease-out, opacity .3s ease-out;
  width: 304px;
  height: auto;
  margin: 8px 8px 0 0;
  padding: 16px 40px 16px 16px;
  opacity: 1;
}

.notification.active>.notification__close-icon {
  -webkit-transition: opacity .15s ease-out .3s;
  -o-transition: opacity .15s ease-out .3s;
  transition: opacity .15s ease-out .3s;
  opacity: 1;
}

.notification__close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background: url("./img/cancel-icon.blue.svg") no-repeat center;
  opacity: 0;
}

.notification__wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
}